<template>
    <div>
        <Navbar page="Finalidades" link="/cadastros" nameLink="Cadastros" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">            
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12">
                                <label for="titulo" class="block text-sm font-medium">Título finalidade</label>
                                <input v-model="form.titulo" type="text" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12">
                                <label for="baselegal" class="block text-sm font-medium">
                                    Base legal 
                                    <router-link to="/cadastros/baselegais" class="text-blue-500 text-sm_2 ml-2">
                                        Visualizar
                                    </router-link>
                                </label>
                                <select @change="ehBaseII()" required v-model="form.baselegal" name="baselegal" id="baselegal" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="baselegal in baselegais" :key="baselegal._id" :value="baselegal._id">
                                        <span class="block w-96">
                                            {{ baselegal.nome }}
                                        </span>
                                    </option>
                                </select>
                                <small class="block text-sm mt-2">Caso deseje, atribua uma base legal a finalidade que está sendo {{ form._id ? 'editada' : 'criada' }} </small>
                            </div>
                        </div>
                    </div>
                    
                    <div class="px-5 pb-4" v-if="temLegis">
                        <button @click="form.legislacoes.push(null)" type="button" class="inline-flex justify-center py-2 px-6 mb-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400">
                            Adicionar Legislação
                        </button>

                        <div v-for="(item, idx) in form.legislacoes" :key="idx" class="grid grid-cols-12 gap-6 mb-3">
                            <div class="col-span-12 md:col-span-3">
                                <label for="baselegal" class="block text-sm font-medium">Legislação Específica</label>
                                <select required v-model="form.legislacoes[idx]" name="baselegal" id="baselegal" class="mt-1 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="legislacao in legislacoes" :key="legislacao._id" :value="legislacao._id">{{ legislacao.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-2">
                                <label for="baselegal" class="block text-sm font-medium">Remover Legislação</label>
                                <button @click="form.legislacoes.splice(idx, 1)" type="button" class="inline-flex justify-center py-2 px-6 mt-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-700">
                                    Remover Legislação
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'finalidades',
            form: {
                titulo: '',
                baselegal: null,
                legislacoes: []
            },
            temLegis: false,
            baselegais: [],
            legislacoes: []
        }
    },
    methods: {
        ehBaseII() {
            if (this.baselegais.length) {
                const baseselecionada = this.baselegais.find(el => el._id.toString() == this.form.baselegal.toString());
                
                if (baseselecionada) {
                    this.temLegis = baseselecionada.nome.indexOf("cumprimento de obrigação") > -1;
                } else {
                    this.temLegis = false;
                }
            } else {
                this.temLegis = false;
            }

        },
        async save() {

            const method = this.form._id ? 'put' : 'post';

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }

        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const reqBase = await this.$http.post('/v1/baselegais/list', { all: true });
        this.baselegais = reqBase.data.data;

        const legislacoesReqReq = await this.$http.post(`/v1/legislacoes/list`, { all: true });
        this.legislacoes = legislacoesReqReq.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;

            this.ehBaseII();
        }
    },
}
</script>